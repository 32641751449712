<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
const $auth = useAuth()
const colorMode = useColorMode()

const color = computed(() => colorMode.value === 'dark' ? '#18181b' : 'white')
const permission = computed(() => route.meta.permission)
const permissions = computed(() => route.meta.permissions as string[])

onMounted(() => {
  colorMode.preference = 'light'
  gainAccess()
})
watch(
  permission || permissions,
  () => {
    gainAccess()
  }
)
const gainAccess = () => {
  if ((permission.value && !$auth.can(permission.value as string)) || (permissions.value && !$auth.canSome(permissions.value))) {
    return showError({
      statusCode: 403,
      message: 'Не достаточно прав доступа'
    })
  }
}

useHead({
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width,height=device-height,initial-scale=1,user-scalable=0,minimum-scale=1,maximum-scale=1'
    },
    {
      key: 'theme-color',
      name: 'theme-color',
      content: color
    }
  ],
  htmlAttrs: {
    lang: 'ru'
  },
  bodyAttrs: {
    class: 'antialiased font-sans'
  }
})
</script>

<style>
html, body, #__nuxt {
  @apply overflow-x-hidden;
}
</style>
